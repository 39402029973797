<template>
  <div class="edit-upload">
    <div class="file-wrap">
      <div
        class="item item_none"
        v-show="!form.data.grzp"
        @click="StartSelectFile('input1')"
        @drop="drop"
        @dragenter="dragenter"
        @dragleave="dragleave"
        @dragover="dragover"
      >
        <img src="./img/img1.png" alt="照片" />
        <div class="text">将要上传的图片拖拽到此处或者 单击鼠标左键</div>
      </div>
      <el-tooltip class="item" effect="dark" content="点击可以修改照片" placement="bottom">
        <div class="item" v-show="form.data.grzp" @click="StartSelectFile('input1')">
          <img
            class="grzp_img"
            :src="'https://jxssft.rmpsy.jxtongke.com/api/'+form.data.grzp.path"
            alt="照片"
            v-if="form.data.grzp"
          />
        </div>
      </el-tooltip>

      <div
        class="item item_none"
        v-show="!form.data.sfztxm"
        @click="StartSelectFile('input2')"
        @drop="drop"
        @dragenter="dragenter"
        @dragleave="dragleave"
        @dragover="dragover"
      >
        <img src="./img/img2.png" alt="身份证头像面" />
        <div class="text">将要上传的图片拖拽到此处或者 单击鼠标左键</div>
      </div>
      <el-tooltip class="item" effect="dark" content="点击可以修改身份证头像面" placement="bottom">
        <div class="item" v-show="form.data.sfztxm" @click="StartSelectFile('input2')">
          <img
            class="sfztxm_img"
            :src="'https://jxssft.rmpsy.jxtongke.com/api/'+form.data.sfztxm.path"
            alt="照片"
            v-if="form.data.sfztxm"
          />
        </div>
      </el-tooltip>
      <div
        class="item item_none"
        v-show="!form.data.sfzghm"
        @click="StartSelectFile('input3')"
        @drop="drop"
        @dragenter="dragenter"
        @dragleave="dragleave"
        @dragover="dragover"
      >
        <img src="./img/img3.png" alt="身份证国徽面" />
        <div class="text">将要上传的图片拖拽到此处或者 单击鼠标左键</div>
      </div>
      <el-tooltip class="item" effect="dark" content="点击可以修改身份证国徽面" placement="bottom">
        <div class="item" v-show="form.data.sfzghm" @click="StartSelectFile('input3')">
          <img
            class="sfzghm_img"
            :src="'https://jxssft.rmpsy.jxtongke.com/api/'+form.data.sfzghm.path"
            alt="照片"
            v-if="form.data.sfzghm"
          />
        </div>
      </el-tooltip>
    </div>
    <div class="info">
      备注：带
      <span>*</span>号的为必填
    </div>
    <div class="nextStep">
      <el-button type="primary" @click="submit(true)">提交修改</el-button>
    </div>
    <input type="file" ref="input1" v-show="false" @change="change($event,'grzp')" />
    <input type="file" ref="input2" v-show="false" @change="change($event,'sfztxm')" />
    <input type="file" ref="input3" v-show="false" @change="change($event,'sfzghm')" />
  </div>
</template>

<script>
import Candidate from '@/api/choose/candidate';
export default {
  name: 'upload',
  props: { info: Object },
  components: {},
  data() {
    return {
      form: {
        data: {
          grzp: '', //照片
          sfztxm: '', //身份证头像面
          sfzghm: '', //身份证国徽面
        },
        placeholder: {
          grzp: '请输入照片', //照片
          sfztxm: '请输入身份证头像面', //身份证头像面
          sfzghm: '请输入身份证国徽面', //身份证国徽面
        },
      },
    };
  },
  methods: {
    StartSelectFile(name) {
      this.selectFile = true;
      this.$nextTick(() => {
        this.$refs[name].click();
      });
    },
    dragenter(e) {
      e.preventDefault();
    },
    dragleave(e) {
      e.preventDefault();
    },
    //拖来拖去 , 一定要注意dragover事件一定要清除默认事件
    //不然会无法触发后面的drop事件
    dragover(e) {
      e.preventDefault();
    },
    //拖拽获取文件事件
    drop(e) {
      let a = { target: { files: [e.dataTransfer.files[0]] } };
      this.change(a);
      e.preventDefault();
    },
    change(e, filed_name) {
      console.log(filed_name);
      let file = e.target.files[0];
      console.log(file);
      let { name, size } = file;
      if (!/png$/.test(name) && !/jpg$/.test(name) && !/jpeg$/.test(name)) {
        this.$message.error('请选择png、jpg、jpeg格式的图片文件');
        return;
      }
      if (size / 1024 / 1024 > 100) {
        this.$message.error('您选择的照片超过了100M');
        return;
      }
      this.file_loading = true;
      let formdata = new FormData();
      formdata.append('file', file);
      Candidate.upload(formdata)
        .then((res) => {
          console.log(res.path);
          this.form.data[filed_name] = res;
        })
        .finally(() => {
          this.file_loading = false;
        });
    },
    toggle(info) {
      let { grzp, sfztxm, sfzghm } = info;
      this.form.data = { grzp, sfztxm, sfzghm };
    },
    submit(submit) {
      if (submit) {
        this.$emit('changeInfo', this.form.data);
        this.$emit('submit');
      } else {
        this.$emit('changeInfo', this.form.data);
        this.$emit('canChangeComponent', true);
      }
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.edit-upload {
  .file-wrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    box-sizing: border-box;
    .item {
      width: 428px;
      height: 270px;
      border: 1px dashed #d9d9d9;
      display: flex;
      border-radius: 8px;
      position: relative;
      cursor: pointer;
      img {
        margin: auto;
        max-height: 80%;
        max-width: 80%;
      }
      .text {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 10px;
        .blue {
          color: #409eff;
        }
      }
    }
    .item:hover {
      border: 1px dashed #409eff;
    }
    .item:nth-of-type(-n + 3) {
      margin-right: 10px;
    }
    .item_none {
      cursor: pointer;
    }
    .item_none:hover {
      border: 1px dashed #409eff;
    }
  }
}
.info {
  margin-top: 100px;
  font-size: 14px;
  color: #0079fe;
  span {
    color: #f56c6c;
  }
}
.nextStep {
  text-align: center;
}
</style>
