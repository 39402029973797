<template>
  <div class="checkCandidate-edit-baseInfo">
    <el-form ref="form" :model="form.data" label-width="120px" :rules="form.rules" class="form">
      <el-col :span="8">
        <el-form-item label="姓名：" prop="xm">
          <el-input v-model="form.data.xm" placeholder="请输入姓名"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="性别：" prop="xb">
          <el-select v-model="form.data.xb" placeholder="请选择性别">
            <el-option v-for="item in form.options.xb.children" :key="item.id" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="重大事项：">
          <el-select v-model="form.data.grzdsxbh" placeholder="请选择重大事项">
            <el-option
              v-for="item in form.options.grzdsxbh.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="民族：" prop="mz">
          <el-select v-model="form.data.mz" placeholder="请选择民族">
            <el-option v-for="item in form.options.mz.children" :key="item.id" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="政治面貌：" prop="zzmm">
          <el-select v-model="form.data.zzmm" placeholder="请选择政治面貌">
            <el-option
              v-for="item in form.options.zzmm.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item label="报名方式：" prop="bmfs">
          <el-select v-model="form.data.bmfs" placeholder="请选择报名方式">
            <el-option
              v-for="item in form.options.bmfs.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="毕业院校：">
          <el-input v-model="form.data.byyx" placeholder="请输入毕业院校"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="文化程度：">
          <el-cascader v-model="form.data.whcd" :show-all-levels="false" :options="form.options.whcd.children"></el-cascader>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="毕业专业：">
          <el-input v-model="form.data.byzy" placeholder="请输入毕业专业"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="身份证号码：" prop="sfzhm">
          <el-input v-model.number="form.data.sfzhm" placeholder="请输入身份证号码"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="联系电话：" prop="lxdh">
          <el-input v-model="form.data.lxdh" placeholder="请输入联系电话"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="QQ号码：">
          <el-input v-model="form.data.qqhm" placeholder="请输入QQ号码"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="微信号：">
          <el-input v-model="form.data.wxh" placeholder="请输入微信号"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="电子邮箱：">
          <el-input v-model="form.data.dzyx" placeholder="请输入电子邮箱"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="邮政编码：">
          <el-input v-model="form.data.yzbm" placeholder="请输入邮政编码"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item label="户籍地：">
          <v-distpicker
            @province="hjdProvince"
            @city="hjdCity"
            @area="hjdArea"
            :province="form.data.hjd && form.data.hjd.province"
          ></v-distpicker>
          <div class="location_error" v-if="hjd_location_error">{{hjd_location_error}}</div>
        </el-form-item>
      </el-col>

      <el-col :span="11">
        <el-form-item label="户籍地详细地址：" prop="hjdxxdz" label-width="160px">
          <el-input v-model="form.data.hjdxxdz" placeholder="请输入户籍地详细地址"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item label="居住地：" class="location">
          <v-distpicker
            @province="jzdProvince"
            @city="jzdCity"
            @area="jzdArea"
            :province="form.data.jzd && form.data.jzd.province"
          ></v-distpicker>
          <div class="location_error" v-if="jzd_location_error">{{jzd_location_error}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="居住地详细地址：" prop="jzdxxdz" label-width="160px">
          <el-input v-model="form.data.jzdxxdz" placeholder="请输入居住地详细地址"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item label="通信地址">
          <v-distpicker
            @province="txdzProvince"
            @city="txdzCity"
            @area="txdzArea"
            :province="form.data.txdz && form.data.txdz.province"
          ></v-distpicker>
          <div class="location_error" v-if="txdz_location_error">{{txdz_location_error}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="通信地址详细地址：" prop="txdzxxdz" label-width="160px">
          <el-input v-model="form.data.txdzxxdz" placeholder="请输入通信地址详细地址"></el-input>
        </el-form-item>
      </el-col>
    </el-form>
    <div class="info">
      备注：带
      <span>*</span>号的为必填
    </div>
    <div class="nextStep">
      <el-button type="primary" @click="submit(true)">下一步</el-button>
    </div>
  </div>
</template>

<script>
import VDistpicker from 'v-distpicker';
export default {
  name: 'baseInfo',
  components: { VDistpicker },
  props: {
    Dictionary: Object,
    info: Object,
  },
  data() {
    return {
      form: {
        data: {
          xm: '', //姓名,
          xb: '', //性别
          grzdsxbh: '', //个人重大事项编号
          sfzhm: '', //身份证号码
          mz: '', //民族
          zzmm: '', //政治面貌
          bmfs: '', //报名方式
          byyx: '', //毕业院校
          whcd: '', //文化程度
          byzy: '', //毕业专业
          qqhm: '', //QQ号码
          wxh: '', //微信号
          lxdh: '', //联系电话
          dzyx: '', //电子邮箱
          yzbm: '', //邮政编码
          jkzk: '', //健康状况
          hjd: '', //户籍地
          hjdxxdz: '', //户籍地详细地址
          jzd: '', //居住地
          jzdxxdz: '', //居住地详细地址
          txdz: '', //通信地址
          txdzxxdz: '', //通信地址详细地址
        },
        rules: {
          xm: { required: true, message: '请输入姓名', trigger: 'change' },
          xb: { required: true, message: '请选择性别', trigger: 'change' },
          mz: { required: true, message: '请选择民族', trigger: 'change' },
          zzmm: { required: true, message: '请选择政治面貌', trigger: 'change' },
          lxdh: { required: true, message: '请输入联系电话', trigger: 'change' },
          jzdxxdz: { required: true, message: '请输入居住地址详细地址', trigger: 'change' },
          sfzhm: { required: true, message: '请输入身份证号码', trigger: 'change' },
          // bmfs: { required: true, message: '请选择报名方式', trigger: 'change' },
        },
        options: {
          xb: { children: [] },
          grzdsxbh: { children: [] },
          mz: { children: [] },
          zzmm: { children: [] },
          whcd: { children: [] },
          bmfs: { children: [] },
        },
      },
      hjd_location_error: '',
      jzd_location_error: '',
      txdz_location_error: '',
      hjd: {
        province: '',
        city: '',
        area: '',
        province_code: '',
        city_code: '',
        area_code: '',
      },
      jzd: {
        province: '',
        city: '',
        area: '',
        province_code: '',
        city_code: '',
        area_code: '',
      },
      txdz: {
        province: '',
        city: '',
        area: '',
        province_code: '',
        city_code: '',
        area_code: '',
      },
      whcdwidth: '',
    };
  },
  methods: {
    // 设置树组件的宽度与当前元素宽度一致，解决树组件过小的问题
    setwidth(e, type) {
      this[type + 'width'] = e.target.offsetWidth;
    },
    whcdClick(data) {
      if (data.has_child) {
        return;
      } else {
        this.form.data.whcd = data.label;
      }
    },
    submit(submit) {
      let hjd = this.check_hjd_location();
      let jzd = this.check_jzd_location();
      let txdz = this.check_txdz_location();
      this.$refs.form.validate((valid) => {
        if (valid && hjd && jzd && txdz) {
          let form = JSON.parse(JSON.stringify(this.form.data));
          if (submit) {
            this.$emit('changeInfo', form);
            this.$emit('changeComponent', 'companyInfo', '修改单位信息', 2);
          } else {
            this.$emit('changeInfo', form);
            this.$emit('canChangeComponent', true);
          }
        } else {
          this.$emit('canChangeComponent', false);
          return false;
        }
      });
    },
    check_hjd_location() {
      // let { province, province_code } = this.hjd;
      // if (!province || !province_code) {
      //   this.hjd_location_error = '请选择户籍地所在省';
      //   return false;
      // }
      return true;
    },
    check_jzd_location() {
      let { province, province_code } = this.form.data.jzd;
      if (!province || !province_code) {
        this.jzd_location_error = '请选择居住地所在省';
        return false;
      }
      return true;
    },
    check_txdz_location() {
      // let { province, province_code } = this.txdz;
      // if (!province || !province_code) {
      //   this.txdz_location_error = '请选择通讯地址所在省';
      //   return false;
      // }
      return true;
    },
    hjdProvince(data) {
      if (data.code) {
        this.form.data.hjd.province = data.value;
        this.form.data.hjd.province_code = data.code.slice(0, 2);
        let { province, province_code } = this.form.data.hjd;
        if (province && province_code) {
          this.hjd_location_error = '';
        }
      }
    },
    hjdCity(data) {
      if (data.code) {
        this.form.data.hjd.city = data.value;
        this.form.data.hjd.city_code = data.code.slice(2, 4);
      }
    },
    hjdArea(data) {
      if (data.code) {
        this.form.data.hjd.area = data.value;
        this.form.data.hjd.area_code = data.code.slice(4, 6);
      }
    },
    jzdProvince(data) {
      if (data.code) {
        this.form.data.jzd.province = data.value;
        this.form.data.jzd.province_code = data.code.slice(0, 2);
        let { province, province_code } = this.form.data.jzd;
        if (province && province_code) {
          this.jzd_location_error = '';
        }
      }
    },
    jzdCity(data) {
      if (data.code) {
        this.form.data.jzd.city = data.value;
        this.form.data.jzd.city_code = data.code.slice(2, 4);
      }
    },
    jzdArea(data) {
      if (data.code) {
        this.form.data.jzd.area = data.value;
        this.form.data.jzd.area_code = data.code.slice(4, 6);
      }
    },
    txdzProvince(data) {
      if (data.code) {
        this.form.data.txdz.province = data.value;
        this.form.data.txdz.province_code = data.code.slice(0, 2);
        let { province, province_code } = this.form.data.txdz;
        if (province && province_code) {
          this.txdz_location_error = '';
        }
      }
    },

    txdzCity(data) {
      if (data.code) {
        this.form.data.txdz.city = data.value;
        this.form.data.txdz.city_code = data.code.slice(2, 4);
      }
    },
    txdzArea(data) {
      if (data.code) {
        this.form.data.txdz.area = data.value;
        this.form.data.txdz.area_code = data.code.slice(4, 6);
      }
    },
    toggle(info) {
      this.form.options = this.Dictionary;
      let {
        xm,
        xb,
        bmfs,
        grzdsxbh,
        zzmm,
        byyx,
        whcd,
        byzy,
        sfzhm,
        lxdh,
        qqhm,
        wxh,
        dzyx,
        yzbm,
        hjd,
        hjdxxdz,
        jzd,
        jzdxxdz,
        txdz,
        txdzxxdz,
        mz,
      } = info;
      if (!bmfs) {
        bmfs = this.form.options.bmfs.children[0].value;
      }
      if (!hjd) {
        hjd = {
          province: '江西省',
          province_code: '36',
        };
      }
      if (!jzd) {
        jzd = {
          province: '江西省',
          province_code: '36',
        };
      }
      if (!txdz) {
        txdz = {
          province: '江西省',
          province_code: '36',
        };
      }
      this.form.data = {
        grzdsxbh,
        zzmm,
        bmfs,
        byyx,
        whcd,
        mz,
        byzy,
        sfzhm,
        lxdh,
        qqhm,
        wxh,
        dzyx,
        yzbm,
        hjd,
        hjdxxdz,
        jzd,
        jzdxxdz,
        txdz,
        txdzxxdz,
        xm,
        xb,
      };
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.checkCandidate-edit-baseInfo {
  display: flex;
  flex-direction: column;
  .el-form {
    .el-select {
      width: 100%;
    }
    .el-cascader {
      width: 100%;
    }
  }
  .info {
    margin-top: 50px;
    font-size: 14px;
    color: #0079fe;
    span {
      color: #f56c6c;
    }
  }
  .nextStep {
    text-align: center;
  }
}
::v-deep .distpicker-address-wrapper {
  display: flex;
  label {
    flex: 1;
    select {
      width: 100%;
      box-sizing: border-box;
      &:focus {
        outline: none;
        border-color: $primary;
      }
    }
  }
  label:nth-of-type(-n + 2) {
    margin-right: 10px;
  }
}
.location_error {
  -webkit-text-size-adjust: 100%;
  word-break: break-all;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: '微软雅黑';
  vertical-align: baseline;
  font-style: initial;
  outline: none;
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
::v-deep .location {
  .el-form-item__label::before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }
}
</style>
