<template>
  <div class="edit-companyInfo">
    <el-form ref="form" :model="form.data" label-width="120px" :rules="form.rules">
      <el-col :span="8">
        <el-form-item label="工作单位：" label-width="160px" prop="gzdw">
          <el-input v-model="form.data.gzdw" placeholder="请输入工作单位"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="单位性质：" prop="dwxz">
          <el-select v-model="form.data.dwxz" placeholder="请选择单位性质">
            <el-option
              v-for="item in form.options.dwxz.children"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="职务级别：">
          <el-select v-model="form.data.zwjb" placeholder="请选择职务级别">
            <el-option
              v-for="item in form.options.zwjb.children"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="参加工作时间：" label-width="160px">
          <el-date-picker v-model="form.data.cjgzsj" value-format="yyyy-MM-dd" type="datetime" placeholder="请选择参加工作时间"></el-date-picker>
          <!-- <el-input v-model="form.data.cjgzsj" placeholder="请选择参加工作时间"></el-input> -->
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="执业类别：">
          <el-select v-model="form.data.zylb" placeholder="请选择执业类别">
            <el-option
              v-for="item in form.options.zylb.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="人大代表：">
          <el-select v-model="form.data.rddb" placeholder="请选择是否人大代表">
            <el-option
              v-for="item in form.options.rddb.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="政协委员：" label-width="160px">
          <el-select v-model="form.data.zxwy" placeholder="请选择是否政协委员">
            <el-option
              v-for="item in form.options.zxwy.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="是否两院院士：">
          <el-select v-model="form.data.sflyys" placeholder="请选择是否两院院士">
            <el-option
              v-for="item in form.options.sflyys.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="是否学部委员：">
          <el-select v-model="form.data.sfxbwy" placeholder="请选择是否学部委员">
            <el-option
              v-for="item in form.options.sfxbwy.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="是否村委会工作人员：" label-width="160px">
          <el-select v-model="form.data.sfcwhgzry" placeholder="请选择是否村委会工作人员">
            <el-option
              v-for="item in form.options.sfcwhgzry.children"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="社会兼职：">
          <el-input v-model="form.data.shjz" placeholder="请输入社会兼职"></el-input>
        </el-form-item>
      </el-col>
    </el-form>
    <div class="info">
      备注：带
      <span>*</span>号的为必填
    </div>
    <div class="nextStep">
      <el-button type="primary" @click="submit(true)">下一步</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'companyInfo',
  props: { Dictionary: Object, info: Object },
  components: {},
  data() {
    return {
      form: {
        data: {
          gzdw: '', //工作单位
          dwxz: '', //单位性质
          zwjb: '', //职务级别
          cjgzsj: '', //参加工作时间
          zylb: '', //职业类别
          rddb: '', //人大代表
          zxwy: '', //政协委员
          sflyys: '', //是否两院院士
          sfxbwy: '', //是否学部委员
          sfcwhgzry: '', //是否村委会工作人员
          shjz: '', //社会兼职
        },
        rules: {
          gzdw: { required: true, message: '请输入工作单位', trigger: 'change' },
          dwxz: { required: true, message: '请选择单位性质', trigger: 'change' },
        },
        options: {
          dwxz: {
            children: [],
          },
          zylb: {
            children: [],
          },
          zwjb: {
            children: [],
          },
          zxwy: {
            children: [],
          },
          sfxbwy: {
            children: [],
          },
          sfcwhgzry: {
            children: [],
          },
          sflyys: {
            children: [],
          },
          rddb: {
            children: [],
          },
        },
      },
    };
  },
  methods: {
    submit(submit) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (submit) {
            this.$emit('changeInfo', this.form.data);
            this.$emit('changeComponent', 'perfectInfo', '修改个人简历', 3);
          } else {
            this.$emit('changeInfo', this.form.data);
            this.$emit('canChangeComponent', true);
          }
        } else {
          this.$emit('canChangeComponent', false);
          return false;
        }
      });
    },
    toggle(info) {
      this.form.options = this.Dictionary;
      let { gzdw, dwxz, zwjb, cjgzsj, zylb, rddb, zxwy, sflyys, sfxbwy, sfcwhgzry, shjz } = info;
      this.form.data = {
        gzdw,
        dwxz,
        zwjb,
        cjgzsj,
        zylb,
        rddb,
        zxwy,
        sflyys,
        sfxbwy,
        sfcwhgzry,
        shjz,
      };
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.edit-companyInfo {
  display: flex;
  flex-direction: column;
  .info {
    margin-top: 50px;
    font-size: 14px;
    color: #0079fe;
    span {
      color: #f56c6c;
    }
  }
  .nextStep {
    text-align: center;
  }
}
::v-deep .el-date-editor {
  width: 100%;
}
.el-select {
  width: 100%;
}
</style>
